import * as React from 'react';
import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material';
import {
    DateField,
    EditButton,
    TextField,
    useListContext,
    RecordContextProvider, ReferenceField, useTheme,
} from 'react-admin';
import {CurrencyNumberField} from "../CurrencyNumberField";

const MobileGrid = () => {
    const { data, error, isPending } = useListContext();
    const [theme, setTheme] = useTheme();
    if (isPending || error || data.length === 0) {
        return null;
    }
    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{ margin: '0.5rem 0' }} style={{ backgroundColor: (record.state === "PROCESSING" && record.paid && record.inStock && !record.hasOpenCoreRefunds ? (theme === "dark" ? "#3d8c3d" : "#efe") : "") }}>
                        <CardHeader
                            title={
                                <>
                                    Order #
                                    <TextField
                                        source="orderNumber"
                                        variant="body1"
                                    />
                                </>
                            }
                            titleTypographyProps={{ variant: 'body1' }}
                            action={<EditButton />}
                        />
                        <CardContent sx={{ pt: 0 }}>
                            <ReferenceField
                                label="Customer"
                                reference="relations"
                                source="customer.id"
                                link={false}
                                sx={{ display: 'block', mb: 1 }}
                            />
                            <Typography variant="body2" gutterBottom>
                                Created at
                                :&nbsp;
                                <DateField source="createdAt" showTime />
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Total
                                :&nbsp;
                                <CurrencyNumberField
                                    source="grandTotal"
                                    currency="currencyCode"
                                    sx={{ fontWeight: "bold" }}
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export default MobileGrid;