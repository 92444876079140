import * as React from "react";
import {
  AutocompleteInput,
  Edit,
  DateInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  number,
  minValue,
  Create,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  TextField,
  ReferenceField,
  NumberField,
} from "react-admin";
import { Grid, Typography } from "@mui/material";
import { Spacer } from "../Spacer";
import { CustomNumberInput } from "../CustomNumberInput";

const validatePurchasePrice = [number(), minValue(0)];
const validatePurchaseShippingPrice = [number(), minValue(0)];
const validateImportDutiesPrice = [number(), minValue(0)];

const PurchaseForm = () => {
  const record = useRecordContext();
  const disabled = record != null && (record.invoiced || record.received);
  return (
    <SimpleForm redirect="show">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Purchase
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <DateInput
                label="Order date"
                source="orderDate"
                validate={[required()]}
                defaultValue={new Date()}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <DateInput label="Invoice date" source="invoiceDate" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <DateInput label="Delivery Date" source="deliveryDate" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput
                label="Order Number"
                source="orderNumber"
                validate={[required()]}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <TextInput label="Remark" source="remark" fullWidth multiline />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Supplier
          </Typography>
          <ReferenceInput
            label="Supplier"
            source="supplier.id"
            reference="relations"
            filter={{ supplier: true, active: true }}
            sort={{ field: "company", order: "ASC" }}
          >
            <AutocompleteInput
              optionText="company"
              fullWidth
              validate={[required()]}
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Spacer />
      <Typography variant="h6" gutterBottom>
        Items
      </Typography>
      <ArrayInput source="items">
        <SimpleFormIterator
          inline
          disableReordering
          disableAdd={disabled}
          disableRemove={disabled}
          disableClear={disabled}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={7} md={4} xl={2}>
              {disabled ? (
                <ReferenceField
                  label="Product"
                  reference="products"
                  source="product.id"
                  filter={{ active: true }}
                  validate={[required()]}
                />
              ) : (
                <ReferenceInput
                  label="Product"
                  reference="products"
                  source="product.id"
                  filter={{ active: true }}
                >
                  <AutocompleteInput validate={[required()]} />
                </ReferenceInput>
              )}
            </Grid>
            <Grid item xs={1} md={2} xl={1}>
              <TextField label="Supplier sku" source="product.skuSupplier" />
            </Grid>
            <Grid item xs={4} md={2} xl={1}>
              {disabled ? (
                <NumberField source="qty" validate={[required()]} />
              ) : (
                <CustomNumberInput source="qty" validate={[required()]} />
              )}
            </Grid>
            <Grid item xs={6} md={2} xl={1}>
              <CustomNumberInput
                source="purchasePrice"
                validate={validatePurchasePrice}
              />
            </Grid>
            <Grid item xs={6} md={2} xl={2}>
              <AutocompleteInput
                label="Currency"
                source="purchaseCurrency"
                choices={[
                  { id: "EUR", name: "EUR" },
                  { id: "USD", name: "USD" },
                  { id: "GBP", name: "GBP" },
                ]}
              />
            </Grid>
            <Grid item xs={6} md={2} xl={1}>
              <CustomNumberInput
                source="purchaseShippingPrice"
                validate={validatePurchaseShippingPrice}
              />
            </Grid>
            <Grid item xs={6} md={2} xl={2}>
              <AutocompleteInput
                label="Currency"
                source="purchaseShippingCurrency"
                choices={[
                  { id: "EUR", name: "EUR" },
                  { id: "USD", name: "USD" },
                  { id: "GBP", name: "GBP" },
                ]}
              />
            </Grid>
            <Grid item xs={6} md={2} xl={1}>
              <CustomNumberInput
                source="importDutiesPrice"
                validate={validateImportDutiesPrice}
              />
            </Grid>
          </Grid>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export const PurchaseCreate = () => {
  return (
    <Create title="Create New Purchase" redirect="show">
      <PurchaseForm />
    </Create>
  );
};

export const PurchaseEdit = () => {
  return (
    <Edit title={<PurchaseTitle />} redirect="show">
      <PurchaseForm />
    </Edit>
  );
};

const PurchaseTitle = () => {
  const record = useRecordContext();
  return (
    <span>
      Purchase{" "}
      {record ? `"${record.supplier.company} ${record.orderNumber}"` : ""}
    </span>
  );
};
